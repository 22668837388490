(function () {
    angular.module('informaApp')
        .service('FilterMapper', FilterMapper);

    function FilterMapper(ConstantsSvc, CompanyModesEnum) {
        return {
            mapToReadableView: (filter, totalPrograms, tableDurationIsMean) => {
                const dateFormat = ConstantsSvc.Dates.fullMomentFormat;

                return mapToReadableHtmlView(filter, totalPrograms, tableDurationIsMean, CompanyModesEnum, dateFormat);
            }
        };
    }

    function mapToReadableHtmlView(filter, totalPrograms, tableDurationIsMean, CompanyModesEnum, dateFormat) {
        const longDash = '&#8212;';

        return {
            timeRange: {
                title: 'Time Range',
                value: `${mapDate(filter.startDate, dateFormat)} ${longDash} ${mapDate(filter.endDate, dateFormat)}`
            },

            selections: mapSelections(filter),

            regulatorySection: mapRegulatorySection(filter),

            companyMode: {
                title: 'Company is',
                value: mapCompanyMode(filter, CompanyModesEnum)
            },

            excludedItems: mapSelections(filter.excludedItems || {}, 'Excluded'),

            totalPrograms: {title: '# of programs', value: totalPrograms},

            tableDurationIsMean: {
                title: 'Duration is',
                value: tableDurationIsMean || tableDurationIsMean === undefined ? 'Mean' : 'Median'
            }
        };
    }

    function mapCompanyMode(filter, CompanyModesEnum) {
        switch (filter.companyMode) {
            case CompanyModesEnum.all:
                return 'either';
            case CompanyModesEnum.partnersOnly:
                return 'partner';
            default:
                return 'lead';
        }
    }

    function mapRegulatorySection(filter) {
        const mappings = [
            {title: 'Fast Track', key: 'fasttrack'},
            {title: 'Breakthrough', key: 'breakthrough'},
            {title: 'SPA', key: 'spa'},
            {title: 'Orphan', key: 'orphan'},
            {title: 'RMAT', key: 'rmat'},
            {title: 'QIDP', key: 'qidp'},
            {title: 'Is First Indication', key: 'isFirstIndication'}
        ];

        return createNewObjectByMapping(mappings, x => mapSingleItem(x, filter, mapBool));
    }

    function mapSelections(filter, prefix) {
        const mappings = [
            {title: 'Indications', key: 'indications'},
            {title: 'Companies', key: 'companies'},
            {title: 'Drug Classes', key: 'drugClasses'},
            {title: 'Molecules', key: 'molecules'},
            {title: 'Targets', key: 'targets'},
            {title: 'Drugs', key: 'drugs'},
            {title: 'Routes', key: 'routes'}
        ];

        return createNewObjectByMapping(mappings, x => mapSingleItem(x, filter, mapArray, prefix));
    }

    function createNewObjectByMapping(mappings, getValue) {
        const result = {};

        _.forEach(mappings, x => result[x.key] = getValue(x));

        return {
            keys: mappings.map(x => x.key),
            source: result
        };
    }

    function mapSingleItem(mapping, filter, mapValue, prefix = '') {
        return {
            title: `${prefix} ${mapping.title}`,
            value: mapValue(filter[mapping.key])
        };
    }

    function mapArray(source) {
        return source
            ? source.map(x => x.name).join(', ')
            : null;
    }

    function mapDate(date, dateFormat) {
        return moment(date).format(dateFormat);
    }

    function mapBool(flag) {
        return flag === null || flag === undefined
            ? 'All'
            : (flag ? 'Yes' : 'No');
    }
})();